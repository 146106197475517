export class Lists {
    static titles = ['Mr', 'Mrs', 'Ms', 'Miss', 'Dr', 'Other'];

    static relationships = [
        'Managing Agent',
        'Letting Agent',
        'Factor',
        'Residents Association',
        'Housing Association',
        'Family Member',
        'Partner',
        'Neighbour',
        'Friend',
        'Tenant',
        'Other'
    ];

    static additionalPolicyholderRelationships = [
        'Spouse',
        'Partner',
        'Mother',
        'Father',
        'Sister',
        'Brother',
        'Other family member',
        'Housing Association',
        'Friend',
        'Other'
    ];

    static representativeRelationships = [
        'Managing Agent',
        'Letting Agent',
        'Factor',
        'Residents Association',
        'Housing Association',
        'Spouse',
        'Partner',
        'Mother',
        'Father',
        'Sister',
        'Brother',
        'Son',
        'Daughter',
        'Other family member',
        'Neighbour',
        'Friend',
        'Tenant',
        'Other'
    ];

    static familyRelationships = [
        'Spouse',
        'Partner',
        'Mother',
        'Father',
        'Sister',
        'Brother',
        'Son',
        'Daughter',
        'Other'
    ];

    static residentialPropertyTypes = [
        'Detached',
        'Semi-detached',
        'Terraced',
        'Apartment'
    ];

    static commercialPropertyTypes = [
        'Hospital',
        'Retail',
        'Office',
        'Education',
        'Industrial',
        'Shopping Centre',
        'Hotel',
        'Unknown',
        'Other'
    ];

    static issueAreasCommon = [
        'Attic',
        'Basement',
        'Bathroom',
        'Bedroom 1',
        'Bedroom 2',
        'Bedroom 3',
        'Bedroom 4',
        'Bedroom 5',
        'Bedroom 6',
        'Carport',
        'Conservatory',
        'Dining Room',
        'Drains',
        'Driveway',
        'Ensuite',
        'External Doors',
        'External Walls',
        'Foundations',
        'Garage',
        'Garden',
        'Hall',
        'Internal Doors',
        'Internal Walls',
        'Kitchen',
        'Living Room',
        'Loft',
        'Orangery',
        'Roads',
        'Roof',
        'Sewers', 
        'Swimming Pool',
        'Windows'
    ];

    static issueAreas = [
        ...Lists.issueAreasCommon,
        'Other'
    ];

    static issueAreasForAdmin = [
        ...Lists.issueAreasCommon,
        'Insolvency - Refund of Deposit',
        'Insolvency of Developer/Builder',
        'Other'
    ];

    static issueEvidenceTypes = [
        'Building Surveyor Report / RICS Report / Structural Engineer Report',
        'Drainage Report',
        'Estimate',
        'Roofer Report',
        'Photographs',
        'Videos',
        'Audio Files',
        'Other Specialist Report',
        'Written Evidence of Notification to Developer',
        'Other'
    ];

    static issueEvidenceReportTypes = [
        'Building Surveyor Report / RICS Report / Structural Engineer Report',
        'Drainage Report',
        'Estimate',
        'Roofer Report',
        'Other Specialist Report',
        'Other'
    ];

    static propertyUsage = [
        'Main Residence',
        'Holiday Home',
        'Rental – Currently Tenanted',
        'Rental – Currently Vacant',
        'Holiday Rental',
        'Other'
    ];

    static closeApplicationReasons = [
        'I can claim under my Home insurance',
        'I can claim under another guarantee/warranty',
        'Developer/Builder will repair',
        'Repair costs are below the Excess',
        'I have already started a notification',
        'I prefer not to say',
        'Other (fill in reason below)'
    ]
}
