import { Aurelia } from 'aurelia-framework'
import environment from './environment';
import { HttpClient } from 'aurelia-fetch-client';
import { PLATFORM } from 'aurelia-pal';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import 'whatwg-fetch'
import { TokenService } from './services/tokenService';
import { setTimeout } from 'timers';

export function configure(aurelia: Aurelia) {
    aurelia.use
        .standardConfiguration()
        .plugin(PLATFORM.moduleName('aurelia-router'))
        .plugin(PLATFORM.moduleName("aurelia-animator-css"))
        .plugin(PLATFORM.moduleName('aurelia-dialog'))
        .feature(PLATFORM.moduleName('resources/index'));

    aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');

    if (environment.testing) {
        aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
    }

    if (!String.prototype.includes) {
        String.prototype.includes = function (search, start) {
            if (typeof start !== 'number') {
                start = 0;
            }

            if (start + search.length > this.length) {
                return false;
            } else {
                return this.indexOf(search, start) !== -1;
            }
        };
    }

    aurelia.start()
        .then(configureAuth)
        .then(() => aurelia.setRoot(PLATFORM.moduleName('app')))
        .catch(x => {
            console.error(x)
        });
    window["au1"] = aurelia;
}

// user manager

async function configureAuth(aurelia: Aurelia): Promise<Aurelia> {
    const httpClient = aurelia.container.get(HttpClient) as HttpClient;
    const tokenService = aurelia.container.get(TokenService) as TokenService;

    httpClient.configure(httpConfig => {
        httpConfig
            .withDefaults({
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
            .withInterceptor({
                async request(request) {

                    const token = tokenService.getToken();

                    if (token) {
                        request.headers.set('Authorization', 'Bearer ' + token);
                    }

                    return request;
                },
                async response(response: Response, request?: Request) {

                    // check if api is offline
                    if (response.status === 503) {
                        window['offline'] = true;
                        window.location.reload();
                    }

                    return response;
                }
            });
    });

    const checkOnline = function () {
        setTimeout(async () => {
            var result = await httpClient.get("api/online");
            if (result.status != 503) {
                checkOnline();
            }
        }, 1000);
    }

    checkOnline();

    return aurelia;
}
