import { Document } from '../application/components/evidence-item';

export class Helpers {
    static handleOther(list: string[], value: string, other: boolean = false) {
        if (value === null || value === "") {
            return null;
        }

        if (list.includes(value)) {
            return !other ? value : null;
        }
        else {
            return other ? value : "Other";
        }
    }

    static isNavigationOrSelectionKey(e: KeyboardEvent) {
        // Allow: backspace, delete, tab, escape, enter
        if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
          // Allow: Ctrl+A/X/C/V, Command+A/X/C/V
          ([65, 67, 86, 88].indexOf(e.keyCode) !== -1 && (e.ctrlKey === true || e.metaKey === true)) ||
          // Allow: home, end, left, right, down, up
          (e.keyCode >= 35 && e.keyCode <= 40)) {
           // let it happen, don't do anything
           return true;
        }

        return false;
    }

    static isSpaceKey(e: KeyboardEvent) {
        return e.keyCode === 32;
    }

    static isNumberKey(e: KeyboardEvent) {
        return !((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105));
    }

    static scrollToElement(element: HTMLElement) {
        const offset = element.getBoundingClientRect().top;
        if (offset < 0) {
            const destination = window.scrollY + offset - 10;
            Helpers.scrollTo(destination, 250, 50);
        }
    }

    static scrollTo(position: number, duration: number = 800, delay: number = 500) {
        setTimeout(() => {
            let cosParameter = (window.pageYOffset - position) / 2,
            scrollCount = 0,
            oldTimestamp = performance.now();
            
            function step (newTimestamp: number) {
                var tsDiff = newTimestamp - oldTimestamp;
                if (tsDiff > 100) {
                    tsDiff = 30;
                }

                scrollCount += Math.PI / (duration / tsDiff);
                
                if (scrollCount >= Math.PI) {
                    return;
                }

                var moveStep = Math.round(position + cosParameter + cosParameter * Math.cos(scrollCount));
                window.scrollTo(0, moveStep);
                oldTimestamp = newTimestamp;
                window.requestAnimationFrame(step);
            }
            
            window.requestAnimationFrame(step);
        }, delay);
    }

    static getFileIcon(file: Document) {
        return file.fileType === 'application/pdf' ? 'pdf' :
               file.fileType.startsWith('image/') ? 'photo' :
               file.fileType.includes('wordprocessing') ? 'word' :
               file.fileType.includes('spreadsheet') ? 'excel' :
               file.fileType.includes('presentation') ? 'powerpoint' :
               'document';
    }

    static isIE() {
        return window.navigator.userAgent.indexOf("MSIE ") > 0 || !!window.navigator.userAgent.match(/Trident.*rv\:11\./);
    }

    static keypressEnterOrSpace(event: KeyboardEvent, callback: Function) {
        if (event.key === 'Enter' || event.key === 'Space' || event.which === 32) {
            callback();
            return false;
        }

        return true;
    }
}