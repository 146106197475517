import { CountryCodeType } from "./gql/updateClaimApplication.tsgql";
export class Enums {
    static countryCodes = [
        { id: CountryCodeType.AFGHANISTAN, description: 'Afghanistan' },
        { id: CountryCodeType.ALBANIA, description: 'Albania' },
        { id: CountryCodeType.ALGERIA, description: 'Algeria' },
        { id: CountryCodeType.AMERICANSAMOA, description: 'American Samoa' },
        { id: CountryCodeType.ANDORRA, description: 'Andorra' },
        { id: CountryCodeType.ANGOLA, description: 'Angola' },
        { id: CountryCodeType.ANGUILLA, description: 'Anguilla' },
        { id: CountryCodeType.ANTARCTICA, description: 'Antarctica' },
        { id: CountryCodeType.ARGENTINA, description: 'Argentina' },
        { id: CountryCodeType.ARMENIA, description: 'Armenia' },
        { id: CountryCodeType.ARUBA, description: 'Aruba' },
        { id: CountryCodeType.AUSTRALIA, description: 'Australia' },
        { id: CountryCodeType.AUSTRIA, description: 'Austria' },
        { id: CountryCodeType.AZERBAIJAN, description: 'Azerbaijan' },
        { id: CountryCodeType.BAHAMAS, description: 'Bahamas' },
        { id: CountryCodeType.BAHRAIN, description: 'Bahrain' },
        { id: CountryCodeType.BANGLADESH, description: 'Bangladesh' },
        { id: CountryCodeType.BARBADOS, description: 'Barbados' },
        { id: CountryCodeType.BELARUS, description: 'Belarus' },
        { id: CountryCodeType.BELGIUM, description: 'Belgium' },
        { id: CountryCodeType.BELIZE, description: 'Belize' },
        { id: CountryCodeType.BENIN, description: 'Benin' },
        { id: CountryCodeType.BERMUDA, description: 'Bermuda' },
        { id: CountryCodeType.BHUTAN, description: 'Bhutan' },
        { id: CountryCodeType.BOLIVIA, description: 'Bolivia' },
        { id: CountryCodeType.BOSNIAANDHERZEGOVINA, description: 'Bosnia and Herzegovina' },
        { id: CountryCodeType.BOTSWANA, description: 'Botswana' },
        { id: CountryCodeType.BRAZIL, description: 'Brazil' },
        { id: CountryCodeType.BRITISHVIRGINISLANDS, description: 'British Virgin Islands' },
        { id: CountryCodeType.BRUNEI, description: 'Brunei' },
        { id: CountryCodeType.BULGARIA, description: 'Bulgaria' },
        { id: CountryCodeType.BURKINAFASO, description: 'Burkina Faso' },
        { id: CountryCodeType.BURUNDI, description: 'Burundi' },
        { id: CountryCodeType.CAMBODIA, description: 'Cambodia' },
        { id: CountryCodeType.CAMEROON, description: 'Cameroon' },
        { id: CountryCodeType.CANADA, description: 'Canada' },
        { id: CountryCodeType.CAPEVERDE, description: 'Cape Verde' },
        { id: CountryCodeType.CAYMANISLANDS, description: 'Cayman Islands' },
        { id: CountryCodeType.CENTRALAFRICANREPUBLIC, description: 'Central African Republic' },
        { id: CountryCodeType.CHILE, description: 'Chile' },
        { id: CountryCodeType.CHINA, description: 'China' },
        { id: CountryCodeType.COLOMBIA, description: 'Colombia' },
        { id: CountryCodeType.COMOROS, description: 'Comoros' },
        { id: CountryCodeType.COOKISLANDS, description: 'Cook Islands' },
        { id: CountryCodeType.COSTARICA, description: 'Costa Rica' },
        { id: CountryCodeType.CROATIA, description: 'Croatia' },
        { id: CountryCodeType.CUBA, description: 'Cuba' },
        { id: CountryCodeType.CURACAO, description: 'Curacao' },
        { id: CountryCodeType.CYPRUS, description: 'Cyprus' },
        { id: CountryCodeType.CZECHREPUBLIC, description: 'Czech Republic' },
        { id: CountryCodeType.DEMOCRATICREPUBLICOFCONGO, description: 'Democratic Republic of Congo' },
        { id: CountryCodeType.DENMARK, description: 'Denmark' },
        { id: CountryCodeType.DJIBOUTI, description: 'Djibouti' },
        { id: CountryCodeType.DOMINICA, description: 'Dominica' },
        { id: CountryCodeType.DOMINICANREPUBLIC, description: 'Dominican Republic' },
        { id: CountryCodeType.EASTTIMOR, description: 'East Timor' },
        { id: CountryCodeType.ECUADOR, description: 'Ecuador' },
        { id: CountryCodeType.EGYPT, description: 'Egypt' },
        { id: CountryCodeType.ELSALVADOR, description: 'El Salvador' },
        { id: CountryCodeType.EQUATORIALGUINEA, description: 'Equatorial Guinea' },
        { id: CountryCodeType.ERITREA, description: 'Eritrea' },
        { id: CountryCodeType.ESTONIA, description: 'Estonia' },
        { id: CountryCodeType.ETHIOPIA, description: 'Ethiopia' },
        { id: CountryCodeType.FALKLANDISLANDS, description: 'Falkland Islands' },
        { id: CountryCodeType.FAROEISLANDS, description: 'Faroe Islands' },
        { id: CountryCodeType.FIJI, description: 'Fiji' },
        { id: CountryCodeType.FINLAND, description: 'Finland' },
        { id: CountryCodeType.FRANCE, description: 'France' },
        { id: CountryCodeType.FRENCHPOLYNESIA, description: 'French Polynesia' },
        { id: CountryCodeType.GABON, description: 'Gabon' },
        { id: CountryCodeType.GAMBIA, description: 'Gambia' },
        { id: CountryCodeType.GEORGIA, description: 'Georgia' },
        { id: CountryCodeType.GERMANY, description: 'Germany' },
        { id: CountryCodeType.GHANA, description: 'Ghana' },
        { id: CountryCodeType.GIBRALTAR, description: 'Gibraltar' },
        { id: CountryCodeType.GREECE, description: 'Greece' },
        { id: CountryCodeType.GREENLAND, description: 'Greenland' },
        { id: CountryCodeType.GUADELOUPE, description: 'Guadeloupe' },
        { id: CountryCodeType.GUAM, description: 'Guam' },
        { id: CountryCodeType.GUATEMALA, description: 'Guatemala' },
        { id: CountryCodeType.GUINEA, description: 'Guinea' },
        { id: CountryCodeType.GUINEABISSAU, description: 'Guinea-Bissau' },
        { id: CountryCodeType.GUYANA, description: 'Guyana' },
        { id: CountryCodeType.HAITI, description: 'Haiti' },
        { id: CountryCodeType.HONDURAS, description: 'Honduras' },
        { id: CountryCodeType.HONGKONG, description: 'Hong Kong' },
        { id: CountryCodeType.HUNGARY, description: 'Hungary' },
        { id: CountryCodeType.ICELAND, description: 'Iceland' },
        { id: CountryCodeType.INDIA, description: 'India' },
        { id: CountryCodeType.INDONESIA, description: 'Indonesia' },
        { id: CountryCodeType.IRAN, description: 'Iran' },
        { id: CountryCodeType.IRAQ, description: 'Iraq' },
        { id: CountryCodeType.IRELAND, description: 'Ireland' },
        { id: CountryCodeType.ISLEOFMAN, description: 'Isle of Man' },
        { id: CountryCodeType.ISRAEL, description: 'Israel' },
        { id: CountryCodeType.ITALY, description: 'Italy' },
        { id: CountryCodeType.IVORYCOAST, description: 'Ivory Coast' },
        { id: CountryCodeType.JAMAICA, description: 'Jamaica' },
        { id: CountryCodeType.JAPAN, description: 'Japan' },
        { id: CountryCodeType.JORDAN, description: 'Jordan' },
        { id: CountryCodeType.KAZAKHSTAN, description: 'Kazakhstan' },
        { id: CountryCodeType.KENYA, description: 'Kenya' },
        { id: CountryCodeType.KIRIBATI, description: 'Kiribati' },
        { id: CountryCodeType.KOSOVO, description: 'Kosovo' },
        { id: CountryCodeType.KUWAIT, description: 'Kuwait' },
        { id: CountryCodeType.KYRGYZSTAN, description: 'Kyrgyzstan' },
        { id: CountryCodeType.LAOS, description: 'Laos' },
        { id: CountryCodeType.LATVIA, description: 'Latvia' },
        { id: CountryCodeType.LEBANON, description: 'Lebanon' },
        { id: CountryCodeType.LESOTHO, description: 'Lesotho' },
        { id: CountryCodeType.LIBERIA, description: 'Liberia' },
        { id: CountryCodeType.LIBYA, description: 'Libya' },
        { id: CountryCodeType.LIECHTENSTEIN, description: 'Liechtenstein' },
        { id: CountryCodeType.LITHUANIA, description: 'Lithuania' },
        { id: CountryCodeType.LUXEMBOURG, description: 'Luxembourg' },
        { id: CountryCodeType.MACAU, description: 'Macau' },
        { id: CountryCodeType.MACEDONIA, description: 'Macedonia' },
        { id: CountryCodeType.MADAGASCAR, description: 'Madagascar' },
        { id: CountryCodeType.MALAWI, description: 'Malawi' },
        { id: CountryCodeType.MALAYSIA, description: 'Malaysia' },
        { id: CountryCodeType.MALDIVES, description: 'Maldives' },
        { id: CountryCodeType.MALI, description: 'Mali' },
        { id: CountryCodeType.MALTA, description: 'Malta' },
        { id: CountryCodeType.MARSHALLISLANDS, description: 'Marshall Islands' },
        { id: CountryCodeType.MAURITANIA, description: 'Mauritania' },
        { id: CountryCodeType.MAURITIUS, description: 'Mauritius' },
        { id: CountryCodeType.MEXICO, description: 'Mexico' },
        { id: CountryCodeType.MICRONESIA, description: 'Micronesia' },
        { id: CountryCodeType.MOLDOVA, description: 'Moldova' },
        { id: CountryCodeType.MONACO, description: 'Monaco' },
        { id: CountryCodeType.MONGOLIA, description: 'Mongolia' },
        { id: CountryCodeType.MONTENEGRO, description: 'Montenegro' },
        { id: CountryCodeType.MONTSERRAT, description: 'Montserrat' },
        { id: CountryCodeType.MOROCCO, description: 'Morocco' },
        { id: CountryCodeType.MOZAMBIQUE, description: 'Mozambique' },
        { id: CountryCodeType.MYANMARBURMA, description: 'Myanmar [Burma]' },
        { id: CountryCodeType.NAMIBIA, description: 'Namibia' },
        { id: CountryCodeType.NAURU, description: 'Nauru' },
        { id: CountryCodeType.NEPAL, description: 'Nepal' },
        { id: CountryCodeType.NETHERLANDS, description: 'Netherlands' },
        { id: CountryCodeType.NEWCALEDONIA, description: 'New Caledonia' },
        { id: CountryCodeType.NEWZEALAND, description: 'New Zealand' },
        { id: CountryCodeType.NICARAGUA, description: 'Nicaragua' },
        { id: CountryCodeType.NIGER, description: 'Niger' },
        { id: CountryCodeType.NIGERIA, description: 'Nigeria' },
        { id: CountryCodeType.NIUE, description: 'Niue' },
        { id: CountryCodeType.NORFOLKISLAND, description: 'Norfolk Island' },
        { id: CountryCodeType.NORTHKOREA, description: 'North Korea' },
        { id: CountryCodeType.NORTHERNMARIANAISLANDS, description: 'Northern Mariana Islands' },
        { id: CountryCodeType.NORWAY, description: 'Norway' },
        { id: CountryCodeType.OMAN, description: 'Oman' },
        { id: CountryCodeType.PAKISTAN, description: 'Pakistan' },
        { id: CountryCodeType.PALAU, description: 'Palau' },
        { id: CountryCodeType.PANAMA, description: 'Panama' },
        { id: CountryCodeType.PAPUANEWGUINEA, description: 'Papua New Guinea' },
        { id: CountryCodeType.PARAGUAY, description: 'Paraguay' },
        { id: CountryCodeType.PERU, description: 'Peru' },
        { id: CountryCodeType.PHILIPPINES, description: 'Philippines' },
        { id: CountryCodeType.PITCAIRNISLANDS, description: 'Pitcairn Islands' },
        { id: CountryCodeType.POLAND, description: 'Poland' },
        { id: CountryCodeType.PORTUGAL, description: 'Portugal' },
        { id: CountryCodeType.PUERTORICO, description: 'Puerto Rico' },
        { id: CountryCodeType.QATAR, description: 'Qatar' },
        { id: CountryCodeType.REPUBLICOFTHECONGO, description: 'Republic of the Congo' },
        { id: CountryCodeType.REUNION, description: 'Reunion' },
        { id: CountryCodeType.ROMANIA, description: 'Romania' },
        { id: CountryCodeType.RUSSIA, description: 'Russia' },
        { id: CountryCodeType.RWANDA, description: 'Rwanda' },
        { id: CountryCodeType.SAINTBARTHELEMY, description: 'Saint Barthélemy' },
        { id: CountryCodeType.SAINTHELENA, description: 'Saint Helena' },
        { id: CountryCodeType.SAINTKITTSANDNEVIS, description: 'Saint Kitts and Nevis' },
        { id: CountryCodeType.SAINTLUCIA, description: 'Saint Lucia' },
        { id: CountryCodeType.SAINTMARTIN, description: 'Saint Martin' },
        { id: CountryCodeType.SAINTPIERREANDMIQUELON, description: 'Saint Pierre and Miquelon' },
        { id: CountryCodeType.SAINTVINCENTANDTHEGRENADINES, description: 'Saint Vincent and the Grenadines' },
        { id: CountryCodeType.SAMOA, description: 'Samoa' },
        { id: CountryCodeType.SANMARINO, description: 'San Marino' },
        { id: CountryCodeType.SAOTOMEANDPRINCIPE, description: 'Sao Tome and Principe' },
        { id: CountryCodeType.SAUDIARABIA, description: 'Saudi Arabia' },
        { id: CountryCodeType.SENEGAL, description: 'Senegal' },
        { id: CountryCodeType.SERBIA, description: 'Serbia' },
        { id: CountryCodeType.SEYCHELLES, description: 'Seychelles' },
        { id: CountryCodeType.SIERRALEONE, description: 'Sierra Leone' },
        { id: CountryCodeType.SINGAPORE, description: 'Singapore' },
        { id: CountryCodeType.SLOVAKIA, description: 'Slovakia' },
        { id: CountryCodeType.SLOVENIA, description: 'Slovenia' },
        { id: CountryCodeType.SOLOMONISLANDS, description: 'Solomon Islands' },
        { id: CountryCodeType.SOMALIA, description: 'Somalia' },
        { id: CountryCodeType.SOUTHAFRICA, description: 'South Africa' },
        { id: CountryCodeType.SOUTHKOREA, description: 'South Korea' },
        { id: CountryCodeType.SOUTHSUDAN, description: 'South Sudan' },
        { id: CountryCodeType.SPAIN, description: 'Spain' },
        { id: CountryCodeType.SRILANKA, description: 'Sri Lanka' },
        { id: CountryCodeType.SUDAN, description: 'Sudan' },
        { id: CountryCodeType.SURINAME, description: 'Suriname' },
        { id: CountryCodeType.SWAZILAND, description: 'Swaziland' },
        { id: CountryCodeType.SWEDEN, description: 'Sweden' },
        { id: CountryCodeType.SWITZERLAND, description: 'Switzerland' },
        { id: CountryCodeType.SYRIA, description: 'Syria' },
        { id: CountryCodeType.TAIWAN, description: 'Taiwan' },
        { id: CountryCodeType.TAJIKISTAN, description: 'Tajikistan' },
        { id: CountryCodeType.TANZANIA, description: 'Tanzania' },
        { id: CountryCodeType.THAILAND, description: 'Thailand' },
        { id: CountryCodeType.TOGO, description: 'Togo' },
        { id: CountryCodeType.TOKELAU, description: 'Tokelau' },
        { id: CountryCodeType.TRINIDADANDTOBAGO, description: 'Trinidad and Tobago' },
        { id: CountryCodeType.TUNISIA, description: 'Tunisia' },
        { id: CountryCodeType.TURKEY, description: 'Turkey' },
        { id: CountryCodeType.TURKMENISTAN, description: 'Turkmenistan' },
        { id: CountryCodeType.TUVALU, description: 'Tuvalu' },
        { id: CountryCodeType.UGANDA, description: 'Uganda' },
        { id: CountryCodeType.UKRAINE, description: 'Ukraine' },
        { id: CountryCodeType.UNITEDARABEMIRATES, description: 'United Arab Emirates' },
        { id: CountryCodeType.UNITEDKINGDOM, description: 'United Kingdom' },
        { id: CountryCodeType.UNITEDSTATES, description: 'United States' },
        { id: CountryCodeType.URUGUAY, description: 'Uruguay' },
        { id: CountryCodeType.UZBEKISTAN, description: 'Uzbekistan' },
        { id: CountryCodeType.VANUATU, description: 'Vanuatu' },
        { id: CountryCodeType.VATICAN, description: 'Vatican' },
        { id: CountryCodeType.VENEZUELA, description: 'Venezuela' },
        { id: CountryCodeType.VIETNAM, description: 'Vietnam' },
        { id: CountryCodeType.WESTERNSAHARA, description: 'Western Sahara' },
        { id: CountryCodeType.YEMEN, description: 'Yemen' },
        { id: CountryCodeType.ZAMBIA, description: 'Zambia' },
        { id: CountryCodeType.ZIMBABWE, description: 'Zimbabwe' },
    ];
}
