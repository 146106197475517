import { customElement, bindable, bindingMode, autoinject, BindingEngine, Disposable, computedFrom } from 'aurelia-framework';
import { Lists } from 'application/lists';
import { validate, ValidateNested, IsNotEmpty, ValidateIf, ArrayMinSize } from 'validation';
import { Update } from 'updateService';
import { ClaimApplicationDtoResult } from 'application/gql/retrieveApplication.tsgql';
import { EvidenceDtoInput } from 'application/gql/createUpdateApplication.tsgql';
import { Helpers } from 'application/helpers';
import { CurrentUser } from '../../user';
import { ApproveEvidenceClient, ApproveEvidenceRequestInput } from '../gql/approveEvidence.tsgql';
import { BindingSignaler } from 'aurelia-templating-resources';
import { BrandService } from '../../services/brandService';

@customElement('evidence-item')
@autoinject()
export class EvidenceItem {
    constructor(
        private bindingEngine: BindingEngine,
        private user: CurrentUser,
        private approveEvidenceClient: ApproveEvidenceClient,
        private bindingSignaler: BindingSignaler,
        private brandService: BrandService) { }

    @bindable applicationId: string;
    @bindable ownerId: string;

    @Update.UpdateNested()
    @ValidateNested()
    @bindable evidence: Evidence;

    @bindable evidenceList: Evidence[];

    @bindable({ defaultBindingMode: bindingMode.twoWay }) allDocuments: Document[];

    @bindable validateParent: Function;
    @bindable documentation: boolean;

    @bindable readOnlyMode: boolean = false;

    showApprovalValidation: boolean = false;

    issueEvidenceTypes = Lists.issueEvidenceTypes;
    issueEvidenceReportTypes = Lists.issueEvidenceReportTypes;
    isValid = true;
    subscriptions: Disposable[] = [];
    elem: HTMLElement;

    attached() {
        this.subscriptions.push(this.bindingEngine.propertyObserver(this.evidence, 'editing').subscribe(async () => { await this.editingChanged(); }));
        this.subscriptions.push(this.bindingEngine.propertyObserver(this.evidence, 'evidenceType').subscribe(() => { if (this.validateParent) { this.validateParent(); } }));
        this.subscriptions.push(this.bindingEngine.propertyObserver(this.evidence, 'documentType').subscribe((n) => { this.documentTypeChanged(n); }));
        if (!!this.evidence.id) {
            this.editingChanged();
        }
    }

    detached() {
        this.subscriptions.forEach(x => x.dispose());
    }

    async editingChanged() {
        const errors = await validate(this);
        this.isValid = this.evidence.editing ? true : errors.length === 0;
    }

    documentTypeChanged(newValue: string) {
        if (this.evidence.evidenceType === 'Proof of Name') {
            if (newValue === 'Driving Licence') {
                const proofOfAddressItem = this.evidenceList.find(x => x.evidenceType === 'Proof of Address');
                if (proofOfAddressItem && proofOfAddressItem.documentType === 'Driving Licence (if address is shown)') {
                    proofOfAddressItem.documentType = '';
                }
            }

            this.bindingSignaler.signal('refresh-document-type-options');
        }
    }

    evidenceType(evidence: Evidence) {
        return (evidence.evidenceType === "Other" ? evidence.evidenceTypeOther : evidence.evidenceType) || "Incomplete Evidence Item";
    }

    edit() {
        if (!this.documentation) {
            this.evidenceList.forEach(x => x.editing = false);
        }

        this.evidence.editing = true;
    }

    remove() {
        const ix = this.evidenceList.indexOf(this.evidence);
        this.evidenceList.splice(ix, 1);
    }

    getFileIcon(file: Document) {
        return Helpers.getFileIcon(file);
    }

    get decisionIsAmendable(){
        if(!this.evidence.approvalDecisionDate){
            return false;
        }

        if(this.user.id !== this.evidence.approvalDecisionById){
            return false;
        }

        const now = new Date();
        const approvalDecisionDate = this.evidence.approvalDecisionDate;
        const deadline = new Date(approvalDecisionDate.getTime() + (15*60000) - 5000);

        const res = now < deadline;
        return res;       
    }

    async approve(decision: boolean) {
        // TODO: I'm not using validation here because it will require a refactor to use groups
        if (decision == false && !this.evidence.approvalAdminNotes) {
            console.log('Approval admin notes required');
            this.showApprovalValidation = true;
        }
        else {
            this.showApprovalValidation = false;
            var req = new ApproveEvidenceRequestInput();
            req.id = this.applicationId;
            req.ownerId = this.ownerId;
            req.evidenceId = this.evidence.id;
            req.approveDecision = decision;
            req.adminNotes = this.evidence.approvalAdminNotes;

            var result = await this.approveEvidenceClient.approveEvidence_approveEvidence(req);
            if (result.approveEvidence.success) {
                this.evidence.approved = decision;
                this.evidence.documents = [];
                this.evidence.approvalDecisionBy = this.user.name;
                this.evidence.approvalDecisionById = this.user.id;
                this.evidence.approvalDecisionDate = new Date();
            }

            this.evidence.editing = false;
        }
    }

    closeKeydown(event: KeyboardEvent) {
        return Helpers.keypressEnterOrSpace(event, () => this.evidence.editing = false);
    }

    get brandTelephone() {
        return this.brandService.brandTelephone;
    }

    @computedFrom('evidence.evidenceType')
    get documentTypeOptions() {
        if (this.evidence.evidenceType === 'Proof of Name') {
            return ['Passport', 'Driving Licence'];
        }
        else if (this.evidence.evidenceType === 'Proof of Address') {
            const options = ['Utility Bill (within the last 3 months)', 'Current Tenancy Agreement'];

            if (!this.evidenceList.some(x => x.evidenceType === 'Proof of Name' && x.documentType === 'Driving Licence' && x.contactId === this.evidence.contactId)) {
                options.push('Driving Licence (if address is shown)');
            }

            return options;
        }
        else if (this.evidence.evidenceType === 'Proof of Ownership of property') {
            return [
                    'Title Deeds',
                    'Mortgage Statement',
                    'Sale Agreement',
                    'Completion Statement',
                    'Current Tenancy Agreement',
                    'Signed Lease Agreement ',
                    'Deed of Conditions'
                ];
        }

        return [];
    }

  @computedFrom('evidence.evidenceType')
  get helpText() {
    if (this.evidence.evidenceType === 'Proof of Name' || this.evidence.evidenceType === 'Proof of Address') {
      return "Two different forms of identification are required for Proof of Name and Address"
    }
    else {
      return null;
    }
  }
}

export class Evidence {
    editing = true;
    documentation = false;
    reportEvidence = false;
    contactName: string;

    id: string;
    contactId: string;

    @IsNotEmpty({ message: "Please select type of evidence" })
    evidenceType: string;

    @IsNotEmpty({ message: "Please enter type of evidence" })
    @ValidateIf((o: Evidence) => o.evidenceType === "Other")
    evidenceTypeOther: string;

    @IsNotEmpty({ message: "Please enter a description" })
    @ValidateIf((o: Evidence) => !o.documentation)
    description: string;

    @Update.IgnoreUpdates()
    requiresApproval: boolean;

    @Update.IgnoreUpdates()
    approved: boolean;

    @Update.IgnoreUpdates()
    approvalAdminNotes: string;

    @Update.IgnoreUpdates()
    approvalDecisionBy: string;

    @Update.IgnoreUpdates()
    approvalDecisionById: string;  

    @Update.IgnoreUpdates()
    approvalDecisionDate: Date;

    @IsNotEmpty({ message: "Please select a document type"})
    @ValidateIf((o: Evidence) => o.documentation && (o.evidenceType === "Proof of Name" || o.evidenceType === "Proof of Address" || o.evidenceType === "Proof of Ownership of property"))
    documentType: string;
    
    @Update.UpdateNestedCollection()
    @ArrayMinSize(1, { message: "Please provide at least one file" })
    @ValidateIf((o: Evidence) => !(o.requiresApproval && o.approved))
    @ValidateNested()
    documents: Document[] = [];

    isEmpty() {
        return !this.evidenceType && !this.description && this.documents.length === 0;
    }

    static fromApplication(app: ClaimApplicationDtoResult, documentation: boolean) {
        return app.evidence.map(x => {
            const evidence = new Evidence();
            evidence.documentation = documentation;
            evidence.id = x.id;
            evidence.evidenceType = x.evidenceType;
            evidence.description = x.description;
            evidence.contactId = x.contactId;
            evidence.approved = x.approved;
            evidence.requiresApproval = x.requiresApproval;
            evidence.approvalAdminNotes = x.approvalAdminNotes;
            evidence.approvalDecisionBy = x.approvalDecisionBy;
            evidence.approvalDecisionById = x.approvalDecisionById;
            evidence.approvalDecisionDate = new Date(x.approvalDecisionDate);
            evidence.documentType = x.documentType;
            evidence.documents = x.documents.map(d => {
                const doc = new Document();
                doc.id = d.id;
                doc.fileId = d.fileId;
                doc.fileName = d.fileName;
                doc.fileSize = d.fileSize;
                doc.fileType = d.fileType;
                return doc;
            });

            return evidence;
        });
    }

    toApplication() {
        const evidence = new EvidenceDtoInput();
        evidence.id = this.id;
        evidence.evidenceType = this.evidenceType;
        evidence.description = this.description;
        evidence.contactId = this.contactId;
        evidence.documentType = this.documentType;
        evidence.documents = this.documents.map(d => {
            const doc = new Document();
            doc.id = d.id;
            doc.fileId = d.fileId;
            doc.fileName = d.fileName;
            doc.fileSize = d.fileSize;
            doc.fileType = d.fileType;
            return doc;
        });

        return evidence;
    }
}

export class Document {
    id: string;
    
    @IsNotEmpty({ message: 'Please provide at least one file' })
    fileId: string;
    
    fileName: string;

    fileType: string;

    fileSize: number;

    @Update.IgnoreUpdates()
    percentUploaded: number;
}
